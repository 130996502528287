import React, { useEffect, useState } from 'react'
import VideoCard from './VideoCard';
import { Link } from 'react-router-dom';
import { onFetchProjects } from '../../Redux/Actions/CommonActions';
import { useDispatch } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';
import { BsArrowRight } from 'react-icons/bs';

const VideoProjects = (props) => {
    const dispatch = useDispatch()
    const [videos, setVideos] = useState([])
    const [loader, setLoader] = useState(false)
console.log(videos, "videos")
    const fetchProjects = () => {
        setLoader(true)
        let limit = props.isDash ? 3 : false
        dispatch(onFetchProjects(setVideos, limit, setLoader))
    }

    useEffect(() => {
        fetchProjects()
    }, [])
    return (
        <div className={`video-project-wrap ${props.dimension === "9x16" ? "col-7 m-auto" : ""}`} >
            <div className="row ">
                {videos.length > 0 ?
                    videos.filter((ele) => {
                        return props.dimension === ele.dimension || props.dimension === ""
                    }).filter((proj) => {
                        if (props.q !== undefined) {
                            return proj.name.toLowerCase().includes(props.q.toLowerCase())
                        } else {
                            return proj
                        }
                    }).map((curElem, index) => {
                        return (
                            <React.Fragment key={index}>
                                <VideoCard
                                    curElem={curElem}
                                    videos={videos}
                                    setVideos={setVideos}
                                    isDash={props.isDash}
                                />
                            </React.Fragment>
                        )
                    })
                    :
                    <div className="col-md-12">
                        <h4 className="text-center load-icon-help mt-3">
                            {
                                loader ?
                                    <FaSpinner className="spin" style={{ fontSize: 25, color: "#00c4a6" }} /> :
                                    <p style={{ fontSize: 18 }}>
                                        You do not have any "Projects" in your account.
                                    </p>
                            }
                        </h4>
                    </div>
                }
                {props.isDash && videos.length > 0 ?
                    <div className="col-xl-3 col-lg-6">
                        <div className=" video_nxt_wrapper video-project-9x16 ">
                            <Link to={'/projects'}>
                                <div className="video_nxt_btn">
                                    <span>
                                        <BsArrowRight />
                                    </span>
                                </div>
                            </Link>
                        </div>
                    </div> : null}

            </div>

        </div >
    )
}

export default VideoProjects