import React from "react";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoginHeader from "../../Header/LoginHeader";
import Footer from "../../Footer/Footer";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { resetPassword } from "../../../Redux/Actions/AuthActions";
import queryString from "query-string";
import TitleBar from "../../CommonComponents/TitleBar";
import { FaSpinner } from "react-icons/fa";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();

  const [loader, setLoader] = useState(false);
  const [loginInfo, setLoginInfo] = useState({
    password: "",
    confirm_password: "",
    password_token: queryString.parse(location.search).token,
  });
  const [type, setType] = useState({
    password: "password",
    confirm_password: "password",
  });

  const [passwordMsg, setPasswordMsg] = useState({
    msg: "",
    validation: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginInfo({
      ...loginInfo,
      [name]: value,
    });

    if (passwordMsg.validation) {
      setPasswordMsg({ msg: "", validation: false });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{8,}$/;

    if (loginInfo.password) {
      if (pattern.test(loginInfo.password)) {
        if (loginInfo.password === loginInfo.confirm_password) {
          setLoader(true);
          dispatch(resetPassword(loginInfo, setLoader, navigate));
        } else {
          setPasswordMsg({ msg: "Passwords do not match!", validation: true });
        }
      } else {
        setPasswordMsg({
          msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 special character and be at least 8 characters long.",
          validation: true,
        });
      }
    }
  };

  const handleType = (val) => {
    if (val === "pass") {
      setType({ ...type, password: "text" });
      setTimeout(() => {
        setType({ ...type, password: "password" });
      }, 2000);
    } else {
      setType({ ...type, confirm_password: "text" });
      setTimeout(() => {
        setType({ ...type, confirm_password: "password" });
      }, 2000);
    }
  };

  return (
    <>
      <TitleBar title="Reset Password" />
      <div className="login-header">
        <LoginHeader />
      </div>
      <div className="login-wrap mb-5">
        <div className="login-left">
          <div className="welcome-txt">
            <h2><span>Welcome To </span>ClipsReelAI</h2>
            <p>Instantly turn any webpage into a traffic-getting video at the push of a button!</p>
            <h3>..it's Super Easy-to-use</h3>
          </div>
        </div>

        <div className="login-right">
          <div className="login-right-main">
            <h2>Reset Password</h2>
            <form className="login-form" onSubmit={handleSubmit}>
              <div className="input-wrap">
                <label htmlFor="">New Password</label>
                <div className="inp-outer">
                  <input
                    className="inp"
                    type={type.password}
                    placeholder='***********'
                    name="password"
                    value={loginInfo.password}
                    onChange={handleChange}
                    required
                  />
                  <span className="inp-icon" style={{ cursor: "pointer" }} onClick={() => handleType("pass")}><AiOutlineEyeInvisible /></span>
                </div>
              </div>
              <div className="input-wrap">
                <label htmlFor="">Confirm Password</label>
                <div className="inp-outer">
                  <input
                    className="inp"
                    type={type.confirm_password}
                    placeholder='***********'
                    name="confirm_password"
                    value={loginInfo.confirm_password}
                    onChange={handleChange}
                    required
                  />
                  <span className="inp-icon" style={{ cursor: "pointer" }} onClick={() => handleType("confirm-pass")}><AiOutlineEyeInvisible /></span>
                </div>
              </div>
              <p style={{ fontSize: 13, margin: "5px", color: "#DC143C" }}>{passwordMsg.validation ? passwordMsg.msg : ""}</p>
              <div className="input-wrap">
                <button className="theme-btn full" type="submit">
                  <span>Reset Password {loader ? <FaSpinner className="spin" /> : ""}</span>
                </button>
              </div>
            </form>
            <div className="log-option text-center">Sign in? <Link to="/">Click Here</Link></div>
          </div>
        </div>
      </div>
      <div className="login-footer">
        <Footer />
      </div>
    </>
  );
};

export default ResetPassword;
