import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import AudioTrack from '../../../CommonComponents/AudioTrack';
import { fetchData, onUploadMedia } from '../../../../Redux/Actions/CommonActions';
import { FiUpload } from 'react-icons/fi';


const UploadMusic = ({ type, onSelectAudio, selectedAudio, setSelectedAudio }) => {

    const dispatch = useDispatch()
    let allowedExt = ['audio/mpeg'];
    const [percent, setPercent] = useState(0)
    const [audio, setAudio] = useState([])
    const [loader, setLoader] = useState({
        upload: false,
        fetch: false
    })



    const onInputFile = (e) => {
        let allowedSize = 5000000
        if (e.target.files.length > 0) {
            if (allowedExt.includes(e.target.files[0].type)) {
                if (e.target.files[0].size < allowedSize) {
                    const formData = new FormData()
                    formData.append('upload_type', type)
                    formData.append('file', e.target.files[0])
                    dispatch(onUploadMedia(formData, fetchAudios, loader, setLoader, setPercent))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for Music File is 5MB!',
                        confirmButtonColor: "#00D0B0"
                    })
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: "#00D0B0"
                })
            }
        }
    }



    const fetchAudios = () => {
        let data = {
            type: type
        }
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-user-uploaded-file", data, setAudio, loader, setLoader))
    }

    useEffect(() => {
        fetchAudios()
    }, [])

    return (
        <>
            <div className='audio-scroll-2 mt-3'>

                <div className='upload mt-2'>
                    {loader.upload ?
                        <div className='percent-loader-box'>
                            <span className='loader-percent'>{percent}%</span>
                        </div>
                        :
                        <>
                            <FiUpload />
                            <p>Upload Audio</p>
                            <input
                                type="file"
                                onChange={onInputFile}
                            />
                        </>}
                </div>

                <div className="audio-list mt-3">
                    {audio.length > 0 ?
                        audio.map((curElem, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <AudioTrack
                                        name={curElem.name}
                                        url={curElem.url}
                                        type="uploaded"
                                        fetchAudios={fetchAudios}
                                        selectedAudio={selectedAudio}
                                        setSelectedAudio={setSelectedAudio}
                                        onSelectAudio={onSelectAudio}
                                        key={index}
                                        id={curElem.id}
                                    />
                                </React.Fragment>
                            )
                        })
                        :
                        loader.fetch ?
                            <div className='fetch-loader-block' style={{ height: "100%", width: "100%" }}><i className="fa fa-spinner fa-spin fetch-loader" /></div>
                            : "No Audio Uploaded yet"
                    }
                </div>
            </div>
        </>
    );
}

export default UploadMusic;
