import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { onEditClient } from '../../../Redux/Actions/AccountManageAction';
import { FaSpinner } from 'react-icons/fa';

const EditMemberModal = (props) => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [details, setDetails] = useState({
        id: "",
        name: "",
        email: "",
        password: ""
    });
    const [passwordMsg, setPasswordMsg] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDetails({
            ...details,
            [name]: value
        });
        if (name === 'password') {
            setPasswordMsg("");
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
        if (details.password && !pattern.test(details.password)) {
            setPasswordMsg("Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 special character, and be at least 6 characters long.");
            return;
        }

        setLoader(true);
        dispatch(onEditClient(details, props.fetchMembers, props.handleClose, setLoader));
    };

    useEffect(() => {
        setDetails({
            name: props.user.name,
            email: props.user.email,
            id: props.user.id,
            password: ""
        });

        return () => {
            setDetails({
                id: "",
                name: "",
                email: "",
                password: ""
            });
        };
    }, [props]);

    return (
        <Modal className="theme-modal" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <div className="connect-modal">
                    <h4>Edit Team Member</h4>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="input-wrap">
                                <label>Account Type</label>
                                <div className="input-outer">
                                    <input
                                        className="common-inp alt"
                                        type="text"
                                        value={props.type}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="input-wrap">
                                <label>Account Name</label>
                                <div className="input-outer">
                                    <input
                                        className="common-inp alt"
                                        type="text"
                                        name="name"
                                        placeholder="Enter Name"
                                        value={details.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="input-wrap">
                                <label>Email</label>
                                <div className="input-outer">
                                    <input
                                        className="common-inp alt"
                                        type="text"
                                        name='email'
                                        value={details.email}
                                        onChange={handleChange}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="input-wrap">
                                <label>Password</label>
                                <div className="input-outer">
                                    <input
                                        className="common-inp alt"
                                        type="password"
                                        value={details.password}
                                        name='password'
                                        onChange={handleChange}
                                        required
                                    />
                                    {passwordMsg && <div className="validation-message">{passwordMsg}</div>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="group-btn">
                        <div className="row">
                            <div className="col-6">
                                <button className="theme-btn full bdr" onClick={props.handleClose}><span>Cancel</span></button>
                            </div>
                            <div className="col-6">
                                <button className="theme-btn full" type='submit' >
                                    <span>{loader ? <>Updating <FaSpinner className="spin" /></> : "Update"}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default EditMemberModal;
