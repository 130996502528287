import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai"
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import Modal from 'react-bootstrap/Modal';
import { useSelector } from "react-redux";
import ConnectFacebook from "./ConnectFacebook";
import ConnectReel from "./ConnectReel";
import ConnectYoutube from "./ConnectYoutube";
import TitleBar from "../../CommonComponents/TitleBar";

const Integration = () => {

    const auth = useSelector(state => state.auth)
    const socialData = useSelector(state => state.social);
    const [memberShip, setMemberShip] = useState([])

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split('__'))
        }
    }, [auth.user])
 

    return (
        <>
            <Header />
            <TitleBar title="Integration" />
            <div className="site-wrap">
                <div className="site-container">
                    <div className="integration">
                        <h2>Integration</h2>
                        <ul>
                            <li>
                                <a href="#facebook" className="text-light">
                                    <div className="integration-single">
                                        <div className="int-logo"><img alt="" src={require('../../../assets/images/icon/facebook.png')} className={socialData.facebook.length > 0 ? "" : "disable"} /></div>
                                        <p>FaceBook</p>
                                    </div>
                                </a>

                            </li>
                            <li>
                                <a href="#youtube" className="text-light">
                                    <div className="integration-single">
                                        <div className="int-logo"><img alt="" src={require('../../../assets/images/icon/youtube.png')} className={socialData.youtube.length > 0 ? "" : "disable"} /></div>
                                        <p>YouTube</p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#syvid" className="text-light">
                                    <div className="integration-single">
                                        <div className="int-logo"><img alt="" src={require('../../../assets/images/icon/syvid.png')} className={socialData.syvid.length > 0 ? "" : "disable"} /></div>
                                        <p>SyVid</p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#livereel" className="text-light">
                                    <div className="integration-single">
                                        <div className="int-logo"><img alt="" src={require('../../../assets/images/icon/livereel.png')} className={socialData.livereel.length > 0 ? "" : "disable"}/></div>
                                        <p>LiveReel</p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#scriptreel" className="text-light">
                                    <div className="integration-single">
                                        <div className="int-logo"><img alt="" src={require('../../../assets/images/icon/scriptreel.png')} className={socialData.scriptreel.length > 0 ? "" : "disable"} /></div>
                                        <p>ScriptReel</p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#trimreel" className="text-light">
                                    <div className="integration-single">
                                        <div className="int-logo"><img alt="" src={require('../../../assets/images/icon/trim.png')} className={socialData.trimreel.length > 0 ? "" : "disable"}/></div>
                                        <p>TrimReel</p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#sonority" className="text-light">
                                    <div className="integration-single">
                                        <div className="int-logo"><img alt="" src={require('../../../assets/images/icon/sonority.png')} className={socialData.sonority.length > 0 ? "" : "disable"}/></div>
                                        <p>Sonority</p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#videoreel" className="text-light">
                                    <div className="integration-single">
                                        <div className="int-logo"><img alt="" src={require('../../../assets/images/icon/videoIcon.png')} className={socialData.videoreel.length > 0 ? "" : "disable"} /></div>
                                        <p>VideoReel</p>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="connection">
                        <h2>Accounts</h2>
                        <div className="row">
                            <div className="col-sm-12">
                                <ConnectFacebook
                                    data={socialData.facebook}
                                />
                            </div>
                            <div className="col-sm-12">
                                <ConnectYoutube
                                    data={socialData.youtube}
                                />
                            </div>
                            <div className="col-sm-12">
                                <ConnectReel
                                    icon={require('../../../assets/images/icon/syvid.png')}
                                    name="Syvid"
                                    type="syvid"
                                    data={socialData.syvid}
                                />
                            </div>
                            <div className="col-sm-12">
                                <ConnectReel
                                    icon={require('../../../assets/images/icon/livereel.png')}
                                    name="LiveReel"
                                    type="livereel"
                                    data={socialData.livereel}
                                />
                            </div>
                            <div className="col-sm-12">
                                <ConnectReel
                                    icon={require('../../../assets/images/icon/scriptreel.png')}
                                    name="ScriptReel"
                                    type="scriptreel"
                                    data={socialData.scriptreel}
                                />
                            </div>
                            <div className="col-sm-12">
                                <ConnectReel
                                    icon={require('../../../assets/images/icon/trim.png')}
                                    name="TrimReel"
                                    type="trimreel"
                                    data={socialData.trimreel}
                                />
                            </div>
                            <div className="col-sm-12">
                                <ConnectReel
                                    icon={require('../../../assets/images/icon/logo-sonority.png')}
                                    name="Sonority"
                                    type="sonority"
                                    data={socialData.sonority}
                                />
                            </div>
                            <div className="col-sm-12">
                                <ConnectReel
                                    icon={require('../../../assets/images/icon/videoIcon.png')}
                                    name="VideoReel"
                                    type="videoreel"
                                    data={socialData.videoreel}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />



        </>
    )
}

export default Integration;