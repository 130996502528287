import React from "react";
import { AiOutlineEyeInvisible } from "react-icons/ai"
import { Link, useNavigate } from "react-router-dom";
import LoginHeader from "../../Header/LoginHeader";
import Footer from "../../Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { loginUser } from "../../../Redux/Actions/AuthActions";
import TitleBar from "../../CommonComponents/TitleBar";
import { FaSpinner } from "react-icons/fa";

const Login = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth)
    const [loader, setLoader] = useState(false)
    const [loginInfo, setLoginInfo] = useState({
        email: "",
        password: ""
    })
    const [type, setType] = useState("password")

    useEffect(() => {

        if (auth.isAuthenticated) {
            navigate('/dashboard')
        }
    }, [auth])


    const handleChange = (e) => {
        const { name, value } = e.target
        setLoginInfo({
            ...loginInfo,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        dispatch(loginUser(loginInfo, setLoader))
    }

    const handleType = () => {
        setType("text")
        setTimeout(() => {
            setType("password")
        }, 2000)
    }

    return (
        <>
            <TitleBar title="Login" />
            <div className="login-header">
                <LoginHeader />
            </div>
            <div className="login-wrap mb-5">
                <div className="login-left">
                    <div className="welcome-txt">
                        <h2><span>Welcome To </span>ClipsReelAI</h2>
                        <p>Instantly turn any webpage into a traffic-getting video at the push of a button!</p>
                        <h3>..it's Super Easy-to-use</h3>
                    </div>
                </div>

                <div className="login-right">
                    <div className="login-right-main">
                        <h2><span>Sign in</span> to ClipsReel AI</h2>
                        <div className="login-form">
                            <form onSubmit={handleSubmit}>
                                <div className="input-wrap">
                                    <label htmlFor="">Email Address</label>
                                    <div className="inp-outer">
                                        <input
                                            className="inp"
                                            type="email"
                                            placeholder="Email Address"
                                            value={loginInfo.email}
                                            name="email"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="input-wrap">
                                    <label htmlFor="">Passoword</label>
                                    <div className="inp-outer">
                                        <input
                                            className="inp"
                                            type={type}
                                            onChange={handleChange}
                                            value={loginInfo.password}
                                            name="password"
                                            placeholder='Password'
                                            required
                                        />
                                        <span className="inp-icon" style={{ cursor: "pointer" }} onClick={handleType}><AiOutlineEyeInvisible /></span>
                                    </div>
                                </div>
                                <div className="input-wrap">
                                    <button className="theme-btn full" type="submit"><span>Sign in {loader ? <FaSpinner className="spin" /> : ""}</span></button>
                                </div>
                            </form>

                            {/* <div className="input-wrap">
                            <div className="or"><span>OR</span></div>
                        </div> */}

                            {/* <div className="social-sign">
                            <div className="social-sign-icon"><img alt="" src={require('../../../assets/images/icon/google-logo.svg').default} /></div>
                            <button>Sign up with Google</button>
                        </div> */}
                            {/* <div className="social-sign">
                            <div className="social-sign-icon"><img alt="" src={require('../../../assets/images/icon/facebook-logo.svg').default} /></div>
                            <button>Sign up with Facebook</button>
                        </div> */}

                        </div>

                        <div className="log-option text-center">Forgot your account details? <Link to='/forgot-password'>Click here</Link></div>

                    </div>
                    {/* <div className="symbol"><RiRobot2Fill/></div> */}


                </div>


                {/* <div className="log-option">Don’t have an account? <Link to="/sign-up">Sign Up</Link></div> */}
            </div>

            <div className="login-footer"><Footer /></div>
        </>
    )
}

export default Login;